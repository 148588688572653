<template>
  <dashboard-main>
    <div slot="main-content">
      <div class="admin-page-title">
        <small>NEW TESTIMONY</small>
      </div>

      <div class="">
        <div class="card">
          <div class="card-body">
            <h6 class="mb-4">Testimony Details</h6>
            <form action="" @submit.prevent="create($event)" enctype="multipart/form-data">
              <div class="row">
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Title</label>
                    <input type="text" name="title" v-model="testimony.title" class="form-control"
                           placeholder="Person's title">
                  </div>
                </div>
                <div class="col-md-6 col-12">
                  <div class="form-group">
                    <label>Name</label>
                    <input type="text" name="name" class="form-control" v-model="testimony.name"
                           placeholder="Person's Name">
                  </div>
                </div>
                <div class="col-md-12 col-12">
                  <div class="form-group">
                    <label>Testimony</label>
                    <textarea name="testimony" class="form-control" v-model="testimony.testimony"
                              placeholder="Testimony"></textarea>
                  </div>
                </div>
                <div class="col-md-12 col-12">
                  <div class="form-group">
                    <label for="">Choose avatar</label>
                    <input type="file" name="avatar" class="form-control" >
                  </div>
                </div>
              </div>
              <div class="form-group mt-3 text-right">
                <button type="submit" class="btn btn-primary">Add Testimony</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </dashboard-main>
</template>

<script>
import DashboardMain from "@/components/layouts/DashboardMain"
import { mapState } from 'vuex'

export default {
  name: "NewStory",
  components: {
    DashboardMain
  },
  data() {
    return {
      message: '',
      testimony: {
        testimony: '',
        name: '',
        title: ''
      }
    }
  },
  mounted() {
    this.$store.commit('setToken')
  },
  methods: {
    create(event) {
      const { testimony, name, title } = this.testimony
      let form = event.srcElement
      const formdata = new FormData(form)

      if (!title || !testimony || !name) {
        return
      }

      this.$axios.post(`${this.$apiurl}/testimonies/`, formdata, {
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
          .then(() => {
            this.testimony.title = this.testimony.testimony = this.testimony.name = ''
            form.reset()
            //console.log(res.data)
          })
          .catch(error => {
            console.error(error)
            console.log(error.response)
          })
    }
  },
  computed: {
    ...mapState([
      'token'
    ])
  }
}
</script>